import React, { useEffect, useState } from 'react';
import { getCurrencySymbol } from 'utils/Utils';
import { assignTransactionToBill, getAllBills } from 'API/backend_helper';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Loader } from 'components/Svg';

const MatchBillTab = ({
  transaction,
  reloadTransaction,
  reloadTransactions,
  setClose,
}) => {
  const [selectedBill, setSelectedBill] = useState(null);
  const [selectedBillIndex, setSelectedBillIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bills, setBills] = useState([]);
  const [loadBill, setLoadBill] = useState(true);

  const getBillsApi = async () => {
    setLoadBill(true);
    try {
      const res = await getAllBills({
        limit: 1000,
        amount: Math.abs(transaction?.amount),
      });
      setBills(res?.bills?.filter((bill) => bill?.status !== 'paid'));
      setLoadBill(false);
    } catch (e) {
      setLoadBill(false);
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (!transaction?.billInstanceId) {
      getBillsApi();
    } else {
      setLoadBill(false);
    }
  }, [transaction?.billInstanceId]);

  const onAssingInvoice = async () => {
    try {
      setLoading(true);
      const res = await assignTransactionToBill(
        selectedBill,
        selectedBillIndex,
        {
          transactionId: transaction?.id,
        },
      );
      if (typeof res !== 'string') {
        await reloadTransaction({});
        toast.success('Bill matched successfully');
        reloadTransactions({});
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log('error', e);
    }
  };

  return (
    <div>
      <div className="px-10">
        <div
          className="flex flex-col mb-6 overflow-auto scrollbar divide-y divide-[#D0D5DD]"
          style={{
            maxHeight: 'calc(100dvh - 332px)',
            height: 'calc(100dvh - 332px)',
          }}
        >
          {transaction?.matchingBillInstances?.length > 0 &&
            transaction?.matchingBillInstances?.map((bill) => (
              <div key={bill?.billId} className="py-6 first:pt-0">
                <label
                  className="w-fit text-base text-[#667085] flex items-center gap-4 mb-4 cursor-pointer"
                  htmlFor={bill?.billId}
                >
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5"
                    id={bill?.billId}
                    checked={selectedBill === bill?.billId}
                    onChange={() => {
                      if (selectedBill !== bill?.billId) {
                        setSelectedBill(bill?.billId);
                        setSelectedBillIndex(bill?.id);
                      } else {
                        setSelectedBill(null);
                        setSelectedBillIndex(null);
                      }
                    }}
                  />

                  <p className="text-[20px] text-[#101828]">
                    {bill?.bill?.vendor?.name || '--'}
                  </p>
                </label>

                <div className="pl-9 flex flex-col gap-y-2">
                  <div className="text-base text-[#667085] flex gap-4 ">
                    <p className="w-24 min-w-24">Date: </p>
                    <p>
                      {moment(bill?.bill?.firstBillDate).format('MMM DD, YYYY')}
                    </p>
                  </div>
                  <div className="text-base text-[#667085] flex gap-4 ">
                    <p className="w-24 min-w-24">Due Date: </p>
                    <p>
                      {moment(
                        bill?.bill?.dueDate
                          ? bill?.bill?.dueDate
                          : bill?.bill?.firstDueDate,
                      ).format('MMM DD, YYYY')}
                    </p>
                  </div>
                  <div className="text-base text-[#667085] flex gap-4 ">
                    <p className="w-24 min-w-24 text-nowrap">Amount Due: </p>
                    <p>
                      {getCurrencySymbol(bill?.bill?.currency)}
                      {parseFloat(
                        bill?.bill?.unpaidAmount ?? 0,
                      )?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div className="text-base text-[#667085] flex gap-4 ">
                    <p className="w-24 min-w-24 text-nowrap">Total Amount: </p>
                    <p>
                      {getCurrencySymbol(bill?.bill?.currency)}
                      {parseFloat(bill?.bill?.total ?? 0)?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )}
                    </p>
                  </div>
                  <div className="text-base text-[#667085] flex gap-4">
                    <p className="w-24 min-w-24"> Bill # </p>
                    <p>
                      {bill?.bill?.billNumber
                        ? `#${bill?.bill?.billNumber}`
                        : '--'}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          {bills?.length > 0 &&
            transaction?.matchingBillInstances?.length > 0 && (
              <div className="min-h-[1px]  !border-none bg-indigo-500 w-full" />
            )}
          {!bills?.length > 0 && loadBill && (
            <div
              className="flex flex-col gap-2 items-center pb-2 justify-center mt-8"
              style={{
                maxHeight: 'calc(100dvh - 223px)',
                height: 'calc(100dvh - 223px)',
              }}
            >
              <Loader />
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          )}
          {!bills?.length > 0 &&
            !transaction?.matchingBillInstances?.length &&
            !loadBill && (
              <div
                className="text-[18px] text-slate-500 text-center  flex items-center justify-center"
                style={{
                  maxHeight: 'calc(100dvh - 223px)',
                  height: 'calc(100dvh - 223px)',
                }}
              >
                No Matching Bill found
              </div>
            )}

          {bills?.length > 0 &&
            bills?.map((bill) => (
              <div key={bill?.id} className="py-6 first:pt-0">
                <label
                  className="w-fit text-base text-[#667085] flex items-center gap-4 mb-4 cursor-pointer"
                  htmlFor={bill?.id}
                >
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5"
                    id={bill?.id}
                    checked={selectedBill === bill?.id}
                    onChange={() => {
                      if (selectedBill !== bill?.id) {
                        setSelectedBill(bill?.id);
                        setSelectedBillIndex(
                          bill?.instances?.[bill?.instances?.length - 1]?.id,
                        );
                      } else {
                        setSelectedBill(null);
                        setSelectedBillIndex(null);
                      }
                    }}
                  />

                  <p className="text-[20px] text-[#101828]">
                    {bill?.vendor?.name || '--'}
                  </p>
                </label>

                <div className="pl-9 flex flex-col gap-y-2">
                  <div className="text-base text-[#667085] flex gap-4 ">
                    <p className="w-24 min-w-24">Date: </p>
                    <p>{moment(bill?.firstBillDate).format('MMM DD, YYYY')}</p>
                  </div>
                  <div className="text-base text-[#667085] flex gap-4 ">
                    <p className="w-24 min-w-24">Due Date: </p>
                    <p>
                      {moment(
                        bill?.dueDate ? bill?.dueDate : bill?.firstDueDate,
                      ).format('MMM DD, YYYY')}
                    </p>
                  </div>
                  <div className="text-base text-[#667085] flex gap-4 ">
                    <p className="w-24 min-w-24 text-nowrap">Amount Due: </p>
                    <p>
                      {getCurrencySymbol(bill?.currency)}
                      {parseFloat(
                        bill?.instances?.[bill?.instances?.length - 1]
                          ?.unpaidAmount ?? 0,
                      )?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div className="text-base text-[#667085] flex gap-4 ">
                    <p className="w-24 min-w-24 text-nowrap">Total Amount: </p>
                    <p>
                      {getCurrencySymbol(bill?.currency)}
                      {parseFloat(
                        bill?.instances?.[bill?.instances?.length - 1]?.total ??
                          0,
                      )?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div className="text-base text-[#667085] flex gap-4">
                    <p className="w-24 min-w-24"> Bill # </p>
                    <p>
                      {bill?.bill?.billNumber ? `#${bill?.billNumber}` : '--'}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <footer className="px-10 py-6 border-t border-[#D0D5DD]">
        <div className="flex gap-4 justify-between items-center flex-wrap">
          {/* <p className="text-[#667085]">Remaining Amount: $100</p> */}
          <div className="flex gap-4 ml-auto">
            <button
              type="button"
              onClick={() => setClose(null)}
              className="h-11 w-[90px] inline-flex items-center justify-center gap-2 border border-[#667085] rounded-[5px] text-[#667085]"
            >
              Close
            </button>
            {(transaction?.matchingBillInstances?.length > 0 ||
              bills?.length > 0) && (
              <button
                type="button"
                disabled={!selectedBill || loading}
                onClick={onAssingInvoice}
                className="h-11 px-7 inline-flex items-center justify-center gap-2 border border-indigo-500 bg-indigo-500 rounded-[5px] text-white disabled:cursor-not-allowed disabled:bg-indigo-300"
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Apply Payment to Bill
                {/* Apply Payment to Invoice(s) */}
              </button>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MatchBillTab;

import React, { useEffect, useState } from 'react';
import { Loader } from 'components/Svg';
import ModalBasic from 'components/ModalBasic';
import { getSingleTransaction } from 'API/backend_helper';
import Tabs from './Tabs';
import CategoryTab from './CategoryTab';
import DuplicatesTab from './DuplicatesTab';
import MatchInvoiceTab from './MatchInvoiceTab';
import TransferTab from './TransferTab';
import MatchBillTab from './MatchBillTab';
import UndepositedTab from './UndepositedTab';

export const TAB_NAMES = {
  categorize: 'categorize',
  transfer: 'transfer',
  duplicates: 'duplicates',
  matchInvoice: 'matchInvoice',
  matchBill: 'matchBill',
  undepositTransfer: 'undepositTransfer',
};

const CategoryModal = ({
  isOpen,
  setIsOpen,
  allCategoriesIncome,
  allCategoriesExpense,
  reloadTransaction,
  accountModalOpen,
  setAccountModalOpen,
  allCategories,
  accountTypes,
  newAddedCategory,
  setNewAddedCategory,
  handlePopupLogic,
}) => {
  const [activeTab, setActiveTab] = useState(TAB_NAMES.categorize);
  const [localTransaction, setLocalTransaction] = useState(null);
  const [noClick, setNoClick] = useState(false);
  const [loading, setLoading] = useState(false);

  const getTransactionById = async (loading = false) => {
    if (loading) {
      setLoading(true);
    }
    try {
      const res = await getSingleTransaction(isOpen?.id);
      setLocalTransaction(res);
      if (loading) {
        setLoading(false);
      }
    } catch (e) {
      if (loading) {
        setLoading(false);
      }
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (isOpen?.id) {
      setLocalTransaction(isOpen);
      if (
        isOpen?.type === 'Transfer' ||
        isOpen?.categoryAccount?.name === 'Uncategorized Transfer'
      ) {
        setActiveTab(TAB_NAMES?.transfer);
        if (isOpen?.transferMatchings?.length > 0) {
          getTransactionById(true);
        } else {
          getTransactionById(false);
        }
      } else {
        getTransactionById(true);
      }
    } else {
      setLocalTransaction(null);
    }
    return () => {
      setActiveTab(TAB_NAMES.categorize);
      setLocalTransaction(null);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!accountModalOpen) {
      setNoClick(false);
    }
  }, [accountModalOpen]);

  return (
    <ModalBasic
      title="Reconcile Transaction"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      width="!w-[770px]"
      noClick={noClick}
      contentId="transactionsCategoryModal"
    >
      <div>
        <Tabs
          TAB_NAMES={TAB_NAMES}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          transaction={localTransaction || isOpen}
        />
        {activeTab === TAB_NAMES.categorize && (
          <CategoryTab
            allCategoriesIncome={allCategoriesIncome}
            allCategoriesExpense={allCategoriesExpense}
            transaction={localTransaction}
            reloadTransaction={getTransactionById}
            reloadTransactions={reloadTransaction}
            setAccountModalOpen={setAccountModalOpen}
            setNoClick={setNoClick}
            setClose={setIsOpen}
            isOpen={isOpen}
            accountTypes={accountTypes}
            newAddedCategory={newAddedCategory}
            setNewAddedCategory={setNewAddedCategory}
            activeTab={activeTab}
            handlePopupLogic={handlePopupLogic}
          />
        )}
        {loading && activeTab !== TAB_NAMES.categorize ? (
          <div
            className="flex flex-col gap-2 items-center pb-2 justify-center mt-8 min-h-[400px]"
            style={{
              maxHeight: 'calc(100dvh - 223px)',
              height: 'calc(100dvh - 223px)',
            }}
          >
            <Loader />
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <>
            {activeTab === TAB_NAMES.transfer && (
              <TransferTab
                setNoClick={setNoClick}
                transaction={localTransaction}
                reloadTransaction={getTransactionById}
                reloadTransactions={reloadTransaction}
                allCategories={allCategories}
              />
            )}
            {activeTab === TAB_NAMES.undepositTransfer && (
              <UndepositedTab
                setNoClick={setNoClick}
                transaction={localTransaction}
                reloadTransaction={getTransactionById}
                reloadTransactions={reloadTransaction}
                allCategories={allCategories}
              />
            )}

            {activeTab === TAB_NAMES.duplicates && (
              <DuplicatesTab
                transaction={localTransaction}
                reloadTransaction={getTransactionById}
                reloadTransactions={reloadTransaction}
              />
            )}
            {activeTab === TAB_NAMES.matchInvoice && (
              <MatchInvoiceTab
                transaction={localTransaction}
                reloadTransaction={getTransactionById}
                reloadTransactions={reloadTransaction}
                setClose={setIsOpen}
              />
            )}
            {activeTab === TAB_NAMES.matchBill && (
              <MatchBillTab
                transaction={localTransaction}
                reloadTransaction={getTransactionById}
                reloadTransactions={reloadTransaction}
                setClose={setIsOpen}
              />
            )}
          </>
        )}
      </div>
    </ModalBasic>
  );
};

export default CategoryModal;

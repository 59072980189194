import React, { useEffect, useState } from 'react';
import { shutdown, boot } from '@intercom/messenger-js-sdk';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Step1 from './Step1';
import Step2 from './Step2';
import {
  customersCsvImport,
  customersImportCreate,
} from '../../API/backend_helper';
import AlertBox from '../../components/AlertBox';
import { Loader } from '../../components/Svg';
import {
  removeValueIfNotExistInData,
  replaceKeysWithValuesAndConvertToCamelCase,
} from '../../utils/Utils';
import { AnimatedCheckmark } from '../inbox/Svg';
import AppLayout from '../../components/AppLayout';

const ImportCustomers = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const From = searchParams.get('from') || '';

  const [activeStep, setActiveStep] = useState(1);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [parsedInfo, setParsedInfo] = useState([]);
  const [mapping, setMapping] = useState({});
  const [keyArray, setKeyArray] = useState([]);
  const [error, setError] = useState(null);
  const [importedTotals, setImportedTotals] = useState({});

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (activeStep > 1) {
        event.preventDefault();
        event.returnValue = ''; // This line is necessary for some browsers
        return ''; // This line is necessary for other browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [activeStep]);

  useEffect(() => {
    shutdown();
    return () => {
      boot();
    };
  }, []);

  const createKeyArray = (customer) =>
    Object?.keys(customer || {})
      ?.filter?.((key) => key !== '' && key)
      ?.map?.((key) => ({
        name: key?.trim(),
        id: key?.trim(),
      }))
      ?.filter?.((item) => item.name !== '' && item.id !== '');

  const csvFileUpload = async () => {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('document', file);
      const res = await customersCsvImport(data);
      const keys = createKeyArray(res?.data?.customers?.[0]);
      if (res?.data?.customerColumns) {
        const transformedObject = replaceKeysWithValuesAndConvertToCamelCase(
          res?.data?.customerColumns,
        );
        setMapping(
          removeValueIfNotExistInData({ ...(transformedObject ?? {}) }, keys),
        );
      }
      setKeyArray(keys);
      setParsedInfo(res?.data);
      setIsLoading(false);
      setActiveStep(activeStep + 1);
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const importMapping = async () => {
    try {
      setActiveStep(activeStep + 1);
      setIsLoading(true);
      const data = new FormData();
      data.append('document', file);
      data.append('mapping', JSON.stringify(mapping));
      const res = await customersImportCreate(data);
      setImportedTotals(res?.data);
      setIsLoading(false);
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const isDisabled = () => {
    if (activeStep === 1 && file) {
      return false;
    }
    if (activeStep === 2) {
      return false;
    }
    if (activeStep === 3) {
      return false;
    }
    return true;
  };

  const onClickNext = () => {
    if (activeStep === 1 && file) {
      csvFileUpload();
    }
    if (activeStep === 2) {
      const requiredKeys = ['customer'];
      const optionalKeys = [
        'firstName',
        'middleName',
        'lastName',
        'taxNumber',
        'email',
        'phone',
        'paymentTerm',
        'website',
        'shippingStreet',
        'shippingStreet2',
        'shippingCountry',
        'shippingCity',
        'shippingState',
        'shippingZip',
        'billingStreet',
        'billingStreet2',
        'billingCountry',
        'billingCity',
        'billingState',
        'billingZip',
        'notes',
        'status',
        'socialSecurityNumber',
        'mainPhone',
      ];

      const hasRequiredKeys = requiredKeys.every(
        (key) =>
          key in mapping &&
          mapping[key] &&
          mapping[key] !== '--' &&
          mapping[key] !== key,
      );

      if (!hasRequiredKeys) {
        setError('You must choose value for Customer');
        return;
      }
      const newMapping = { ...mapping };
      optionalKeys.forEach((key) => {
        if (!newMapping[key] || newMapping[key] === '--') {
          newMapping[key] = '';
        }
      });
      setMapping(newMapping);
      setError(null);
      importMapping();
    }
  };

  return (
    <AppLayout>
      <main
        className="grow relative overflow-auto flex flex-col"
        id="importCustomersContainer"
      >
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto pb-16">
          {isLoading && activeStep === 2 && (
            <AlertBox
              type="warning"
              heading="Vendors Import in Progress"
              text="This may take 2-3 minutes"
              hideActionButton
              icon={<Loader />}
            />
          )}
          <div className="mb-8 flex justify-between">
            {/* Title */}
            <h1 className="text-2xl md:text-[36px] text-[#00000099] dark:text-slate-100 font-medium">
              Import Customers
            </h1>
          </div>
          <div className="flex justify-between mx-6 relative mb-8">
            <div
              className="absolute h-[1px] bg-[#667085] top-[11px] mx-auto left-1/2 -translate-x-1/2"
              style={{
                width: 'calc(100% - 3rem)',
              }}
            />
            <div className="flex flex-col items-center z-10 relative">
              <div
                className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 1
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
              >
                1
              </div>
              <h3>Upload</h3>
            </div>
            <div className="flex flex-col items-center z-10 relative">
              <div
                className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 2
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
              >
                2
              </div>
              <h3>Map Data</h3>
            </div>
            <div className="flex flex-col items-center z-10 relative">
              <div
                className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 3
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
              >
                3
              </div>
              <h3>Import</h3>
            </div>
          </div>
          {activeStep === 1 && (
            <Step1
              error={fileError}
              setError={setFileError}
              file={file}
              setFile={setFile}
            />
          )}
          {activeStep === 2 && (
            <Step2
              mapping={mapping}
              setMapping={setMapping}
              parsedInfo={parsedInfo}
              keyArray={keyArray}
              error={error}
            />
          )}
          {activeStep === 3 && (
            <div className="w-full h-full">
              {isLoading && (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <Loader />
                  <p>Importing your customers...</p>
                </div>
              )}
              {!isLoading && (
                <div className="w-full flex items-center justify-center flex-col">
                  <AnimatedCheckmark />
                  <AlertBox
                    type="success"
                    heading="Customers Imported Successfully"
                    text="Your customers data is being imported in the background. We’re handling everything for you. You will be informed by email when the import is complete."
                    hideActionButton
                  />
                  <div className="flex gap-2 mt-6">
                    <button
                      className="whitespace-nowrap w-[13rem] h-11 py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-white text-slate-600"
                      onClick={() => {
                        setActiveStep(1);
                        setFile(null);
                        setFileError('');
                        setParsedInfo([]);
                        setMapping({});
                        setKeyArray([]);
                      }}
                      type="button"
                    >
                      Import More Customers
                    </button>
                    {From === 'onboarding' ? (
                      <button
                        className="whitespace-nowrap w-[16rem] h-11 py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-indigo-500 text-white"
                        onClick={() => {
                          navigate('/onboarding');
                        }}
                        type="button"
                      >
                        Back to On Boarding
                      </button>
                    ) : (
                      <button
                        className="whitespace-nowrap w-[13rem] h-11 py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-indigo-500 text-white"
                        onClick={() => {
                          navigate('/customers');
                        }}
                        type="button"
                      >
                        Back to Customers
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
      <footer
        className="absolute bottom-0 h-16 bg-white w-full border-t border-[#D0D5DD80] px-6
        flex justify-between items-center"
      >
        {activeStep === 1 ? (
          <Link to="/customers">
            <button
              // disabled
              className="btn h-fit border-[#667085] rounded-full px-6 font-medium disabled:opacity-80 disabled:cursor-not-allowed"
              type="button"
            >
              Back to Customers
            </button>
          </Link>
        ) : (
          <button
            onClick={() => {
              if (activeStep === 2) {
                setActiveStep((prev) => prev - 1);
              }
            }}
            disabled={activeStep === 3}
            className="btn h-fit border-[#667085] rounded-full px-6 font-medium disabled:opacity-80 disabled:cursor-not-allowed"
            type="button"
          >
            Back
          </button>
        )}
        {activeStep !== 3 && (
          <button
            disabled={isDisabled() || isLoading}
            className="z-[2000000002] btn h-fit rounded-full px-6 !bg-indigo-500 text-white font-medium border-indigo-500
            disabled:!bg-indigo-400 disabled:cursor-not-allowed"
            onClick={onClickNext}
            type="button"
          >
            {isLoading && (
              <svg
                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            )}

            {activeStep === 3 ? 'Import' : 'Next'}
          </button>
        )}
      </footer>
    </AppLayout>
  );
};

export default ImportCustomers;

import { toast } from 'react-toastify';
import axios from 'axios';
import AxiosInstance, { getTeamId } from './axiosIntance';
import * as url from './url_helper';

const accessToken = localStorage.getItem('accessToken');

export const getCountriesData = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.GET_COUNTRIES}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getBusinessStructuresData = async (countryCode = '') => {
  try {
    const response = await AxiosInstance.get(
      `${url?.GET_STRUCTURES}?countryCode=${countryCode}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getCurrenciesData = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.GET_CURRENCIES}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// People Auth
export const peopleSignIn = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.PEOPLE_SIGN_IN}`, data);
    return response?.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return error?.response?.data?.message;
    }
    return 'Oops! Something unexpected occurred. Please contact support for assistance.';
  }
};

// Accountant Firm

export const createAccountantFirm = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.ACCOUNTING_FIRM}`, data);
    return response?.data;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};

export const getAccountantFirms = async (id) => {
  try {
    const response = await AxiosInstance.get(`${url?.ACCOUNTING_FIRM}/${id}`);
    return response?.data;
  } catch (error) {
    return error?.response?.data?.message;
  }
};

export const getAllAccountantFirms = async ({
  page = 1,
  limit = 50,
  all = false,
  search = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.ACCOUNTING_FIRM}/active?page=${page}&limit=${limit}${all ? '&certified=true,false' : ''}&search=${search}`,
    );
    return response?.data;
  } catch (error) {
    return error?.response?.data?.message;
  }
};

export const updateAccountantFirm = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.ACCOUNTING_FIRM}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};

// Bank Statement

export const processBankStatement = async (data) => {
  try {
    const response = await axios.post(
      `https://count-process-documents-prod.azurewebsites.net/document/process-bank-statement?output_format=csv`,
      data,
    );
    return response?.data;
  } catch (error) {
    return error?.response?.data?.message;
  }
};

// Auth

export const signUp = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.SIGN_UP}`, data);
    return response?.data;
  } catch (error) {
    return error?.response?.data?.message;
  }
};

export const checkUserEmailAuth = async (email) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.CHECK_EMAIL}?email=${email}`,
    );
    return response?.data;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};

export const signIn = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.SIGN_IN}`, data);
    return response?.data?.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return error?.response?.data?.message;
    }
    return 'Oops! Something unexpected occurred. Please contact support for assistance.';
  }
};

export const twoFactorAuth = async (email, code) => {
  try {
    const response = await AxiosInstance.post(`${url?.VERIFY_OTP}`, {
      code,
      email,
    });
    return response?.data?.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return error?.response?.data?.message;
    }
    return 'Oops! Something unexpected occurred. Please contact support for assistance.';
  }
};

export const forgotPassword = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.FORGOT_PASSWORD}`, data);
    return response?.data;
  } catch (error) {
    return error?.response?.data?.message;
  }
};

export const peopleForgotPassword = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.FORGOT_PASSWORD_PERSON}`,
      data,
    );
    return response?.data;
  } catch (error) {
    return error?.response?.data?.message;
  }
};

export const verifyEmail = async (email, otp) => {
  try {
    const response = await AxiosInstance.patch(`${url?.VERIFY_EMAIL}`, {
      otp,
      email,
    });
    return response?.data;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};

export const resendVerifyOtp = async (email) => {
  try {
    const response = await AxiosInstance.post(`${url?.RESEND_VERIFY_EMAIL}`, {
      email,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
};

export const resetPassword = async (token, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.RESET_PASSWORD}/${token}`,
      data,
    );
    return response?.data;
  } catch (error) {
    return error?.response?.data?.message;
  }
};

export const personResetPassword = async (token, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.RESET_PASSWORD_PERSON}/${token}`,
      data,
    );
    return response?.data;
  } catch (error) {
    return error?.response?.data?.message;
  }
};

export const getUser = async (localToken = null, teamId = null) => {
  try {
    const response = await AxiosInstance.get(`${url?.GET_USER}`, {
      headers: {
        Authorization: `Bearer ${localToken || accessToken}`,
        'count-workspace-id': teamId || getTeamId(),
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const checkPromoCodeValidity = async (code) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.PROMO_CODE_VALIDITY}/${code}`,
    );
    return response?.data;
  } catch (error) {
    // toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getUserSettings = async () => {
  try {
    const response = await AxiosInstance.get(
      `${url?.GET_USER}${url?.USER_SETTINGS}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateUserSettings = async (data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.GET_USER}${url?.USER_SETTINGS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateUser = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(`${url?.GET_USER}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const response = await AxiosInstance.patch(`${url?.CHANGE_PASSWORD}`, {
      password: oldPassword,
      newPassword,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const changePersonPassword = async (oldPassword, newPassword) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.CHANGE_PASSWORD_PERSON}`,
      {
        oldPassword,
        newPassword,
      },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateUserAvatar = async (id, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.GET_USER}${url?.AVATAR}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getConnections = async (localToken = null, teamId = null) => {
  try {
    const response = await AxiosInstance.get(`${url?.GET_CONNECTIONS}`, {
      headers: {
        Authorization: `Bearer ${localToken || accessToken}`,
        'count-workspace-id': teamId || getTeamId(),
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getPopularBanks = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.GET_POPULAR_BANKS}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};
// Plaid
export const createPlaidToken = async (
  localToken = null,
  data = {},
  routingNumber = '',
) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.CREATE_PLAID_TOKEN}?routingNumber=${routingNumber}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localToken || accessToken}`,
        },
      },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateConnectionLink = async (connectionId) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.ACCOUNT_CONNECTIONS}/${connectionId}${url?.UPDATE_LINK_TOKEN}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const exchangePlaidToken = async (localToken = null, data = null) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.EXCHANGE_PLAID_TOKEN}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localToken || accessToken}`,
        },
      },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const removePlaidAlerts = async (connectionId = '') => {
  try {
    const response = await AxiosInstance.get(
      `${url?.GET_CONNECTIONS}/${connectionId}${url?.RESOLVED_ALERTS}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const plaidSyncStart = async (localToken = null, data = null) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.PLAID_SYNC_START}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localToken || accessToken}`,
        },
      },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Akahu
export const createAkahuUrl = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.AKAHU}/auth/url`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const revokeAkahuConnection = async (connectionId) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.ACCOUNT_CONNECTIONS}/${connectionId}${url?.AKAHU_REVOKE_TOKKEN}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getAccountReconcileDetails = async (accountId) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.RECONCILE_ACCOUNT}${url?.ACCOUNT}/${accountId}${url?.RECONCILE_OPENING_BALANCE}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const checkAutoReconcile = async (accountId) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.RECONCILE_ACCOUNT}${url?.ACCOUNT}/${accountId}${url?.AUTO_RECONCILE}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getAccountOpeningBalance = async (accountId, endDate) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.RECONCILE_ACCOUNT}${url?.ACCOUNT}/${accountId}${url?.RECONCILE_OPENING_BALANCE}?statementEndDate=${endDate}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getPossibleReconcileAccounts = async () => {
  try {
    const response = await AxiosInstance.get(
      `${url?.RECONCILE_ACCOUNT}${url?.ACCOUNTS}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteAccountReconcilation = async (
  accountId = null,
  reconciliationId = '',
) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.RECONCILE_ACCOUNT}${url?.ACCOUNT}/${accountId}/delete?reconcileBalanceId=${reconciliationId}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateAccountReconcilationDraft = async (accountId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.RECONCILE_ACCOUNT}${url?.ACCOUNT}/${accountId}/update`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getReconciliationHistory = async (accountId) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.RECONCILE_ACCOUNT}${url?.ACCOUNT}/${accountId}/balances`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const completeAccountReconcilation = async ({
  accountId,
  allowUnreviewed = false,
}) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.RECONCILE_ACCOUNT}${url?.ACCOUNT}/${accountId}${url?.RECONCILE_COMPLETE}?allowUnreviewed=${allowUnreviewed}`,
      {},
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getAccountTransDetails = async (
  accountId,
  startDate = '',
  endDate = '',
) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.GET_ACCOUNTS}/${accountId}${url?.ACCOUNT_TRANS_DETAILS}?startDate=${startDate}&endDate=${endDate}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const saveReconcilation = async (accountId, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.RECONCILE_ACCOUNT}${url?.ACCOUNT}/${accountId}${url?.RECONCILE_SAVE}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getAccountTypes = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.GET_ACCOUNT_TYPES}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getAccountSubTypes = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.GET_ACCOUNT_SUB_TYPES}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getAccounts = async ({
  typeId = [],
  search = '',
  includeBalances = false,
  notAssignedToReporter = '',
  inactive = false,
} = {}) => {
  try {
    const typeString = `${typeId.map((id) => `typeId=${id}`).join('&')}`;
    const response = await AxiosInstance.get(
      `${url?.GET_ACCOUNTS}?${typeString}&search=${search}&includeBalances=${includeBalances}&notAssignedToReporter=${notAssignedToReporter}${inactive ? `&inactive=${inactive}` : ''}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getAccountsByExpenseReporter = async (reporterId) => {
  try {
    const response = await AxiosInstance.get(`/people/accounts/${reporterId}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getAccountSystemBalance = async (accountIds) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.GET_ACCOUNTS}${url?.GET_ACCOUNT_SYSTEM_BALANCE}`,
      {
        accountIds,
      },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getAccountById = async (accountId) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.GET_ACCOUNTS}/${accountId}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateAccountById = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.GET_ACCOUNTS}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const createAccount = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.GET_ACCOUNTS}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createDefaultAccount = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.DEFAULT_ACCOUNTS}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateDefaultAccountById = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.DEFAULT_ACCOUNTS}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const deleteDefaultAccount = async (accountId) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.DEFAULT_ACCOUNTS}/${accountId}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Accounts Import
export const accountCsvImport = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.ACCOUNTS_IMPORT_PARSE}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const accountImportMapping = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.ACCOUNTS_IMPORT_MAPPING}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const accountImportBulk = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.ACCOUNTS_IMPORT_BULK}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Transactions Import

export const transactionCsvImport = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.GET_TRANSACTIONS}${url?.IMPORT}${url?.PARSE}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const transactionImportCreate = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.GET_TRANSACTIONS}${url?.IMPORT}${url?.CREATE}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getTransactions = async ({
  page = 1,
  limit = 50,
  status = '',
  accounts = [],
  search = '',
  startDate = '',
  endDate = '',
  accountCategories = '',
  accountSubTypes = '',
  orderBy = '',
  orderDirection = '',
  transactionTypes = [],
  accountTypes = [],
  vendors = [],
  tags = [],
  currency = '',
  customers = [],
  projects = '',
  reviewed = '',
  pending = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${
        url?.GET_TRANSACTIONS
      }?page=${page}&limit=${limit}&accounts=${accounts}&accountTypes=${accountTypes}&transactionTypes=${transactionTypes}&status=${status}&search=${search}&accountCategories=${accountCategories}&orderBy=${orderBy}&orderDirection=${orderDirection}&startDate=${startDate}&endDate=${
        endDate || startDate
      }${reviewed !== '' ? `&reviewed=${reviewed}` : ''}${pending !== '' ? `&pending=${pending}` : ''}&vendors=${vendors}&tags=${tags}&accountSubTypes=${accountSubTypes}&currency=${currency}&customers=${customers}&projects=${projects}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createTransaction = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.GET_TRANSACTIONS}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const splitTransactionsAPI = async (id, data) => {
  try {
    const response = await AxiosInstance.put(
      `${url?.GET_TRANSACTIONS}/${id}${url?.BILL_SPLIT}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getTransactionMonthlyAnalytics = async ({
  status = '',
  accounts = [],
  search = '',
  startDate = '',
  endDate = '',
  accountCategories = '',
  accountSubTypes = '',
  orderBy = '',
  orderDirection = '',
  transactionTypes = [],
  accountTypes = [],
  vendors = [],
  tags = [],
  customers = [],
}) => {
  try {
    const response = await AxiosInstance.get(
      `${
        url?.GET_TRANSACTIONS_MONTHLY_ANALYTICS
      }?accounts=${accounts}&transactionTypes=${transactionTypes}&accountTypes=${accountTypes}&status=${status}&search=${search}&accountCategories=${accountCategories}&orderBy=${orderBy}&orderDirection=${orderDirection}&startDate=${startDate}&endDate=${
        endDate || startDate
      }&vendors=${vendors}&tags=${tags}&accountSubTypes=${accountSubTypes}&customers=${customers}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getSingleTransaction = async (id) => {
  try {
    const response = await AxiosInstance.get(`${url?.GET_TRANSACTIONS}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getPendingUnmatchedReceipts = async ({
  page = 1,
  limit = 50,
} = {}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.PENDING_RECEIPT}/unmatched-receipt?page=${page}&limit=${limit}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const addReceipt = async (id, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.GET_TRANSACTIONS}/${id}${url?.RECEIPTS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getReceipt = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.GET_TRANSACTIONS}/${id}${url?.RECEIPTS}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateReceipt = async (transactionId, receiptId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.GET_TRANSACTIONS}/${transactionId}${url?.RECEIPTS}/${receiptId}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteReceipt = async (transactionId, receiptId) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.GET_TRANSACTIONS}/${transactionId}${url?.RECEIPTS}/${receiptId}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const addComment = async (id, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.GET_TRANSACTIONS}/${id}${url?.COMMENTS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getCommentsApi = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.GET_TRANSACTIONS}/${id}${url?.COMMENTS}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getTransactionsMentionedComments = async () => {
  try {
    const response = await AxiosInstance.get(
      `${url?.GET_TRANSACTIONS_MENTIONED_COMMENTS}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getMatchingSuggestionCount = async () => {
  try {
    const response = await AxiosInstance.get(
      `${url?.MATCHING_TRANSACTIONS}${url?.MATCHING_COUNT}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getMatchingTransaction = async ({
  page = 1,
  limit = 50,
  matchingType = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.MATCHING_TRANSACTIONS}?page=${page}&limit=${limit}&matchingType=${matchingType}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const onConfirmMatchingTrans = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.CONFIRM_MATCHING_TRANS}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const onIgnoreMatchingTransaction = async (id) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.MATCHING_TRANSACTIONS}/${id}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const ignoreMatchingTransaction = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.MATCHING_TRANSACTIONS}/${id}/ignore`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const ignoreMatchingSingleTransaction = async (id, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.MATCHING_TRANSACTIONS}${url?.IGNORE_MATCHING_SINGLE_TRANSACTIONS}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getSingleMerchant = async (id) => {
  try {
    const response = await AxiosInstance.get(`${url?.MERCHANTS}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateTransaction = async (transactionId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.GET_TRANSACTIONS}/${transactionId}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const reviewTransaction = async (transactionId) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.GET_TRANSACTIONS}/${transactionId}/toggle-review`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateTransactionCategory = async (transactionId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.GET_TRANSACTIONS}/${transactionId}${url?.CHANGE_CATEGORY}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const bulkUpdateTransactionCategory = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.GET_TRANSACTIONS}${url?.CHANGE_CATEGORY_BULK}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const bulkUpdateReviewTrans = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.BULK_UPDATE_REVIEW_TRANS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const bulkUpdateTransactionVendor = async (data) => {
  try {
    const response = await AxiosInstance.put(
      `${url?.GET_TRANSACTIONS}${url?.MERCHANT}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const bulkUpdateTransactionCustomer = async (data) => {
  try {
    const response = await AxiosInstance.put(
      `${url?.GET_TRANSACTIONS}${url?.CUSTOMER}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Categories

export const getCategoriesGroups = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.GET_CATEGORIES_GROUPS}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createCategoriesGroup = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.GET_CATEGORIES_GROUPS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateCategoriesGroup = async (data, id) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.GET_CATEGORIES_GROUPS}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getCategories = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.GET_CATEGORIES}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createCategory = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.GET_CATEGORIES}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateCategory = async (data, id) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.GET_CATEGORIES}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Customers

export const getCustomers = async ({
  limit = 10000,
  page = 1,
  search = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.CUSTOMERS}?search=${search}&page=${page}&limit=${limit}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getCustomerOverview = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.CUSTOMERS}/${id}/revenue-overview`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getCustomerById = async (id) => {
  try {
    const response = await AxiosInstance.get(`${url?.CUSTOMERS}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const createCustomer = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.CUSTOMERS}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateCustomer = async (data, id) => {
  try {
    const response = await AxiosInstance.patch(`${url?.CUSTOMERS}/${id}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const deleteCustomer = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.CUSTOMERS}/${id}`);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Customers Import

export const customersCsvImport = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.CUSTOMERS}${url?.CUSTOMER_PARSE}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const customersImportCreate = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.CUSTOMERS}${url?.CUSTOMER_IMPORT}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// People

export const createPeople = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.PEOPLE}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getPeople = async ({
  limit = 10,
  page = 1,
  type = '',
  search = '',
  isNotExpenseReporter = false,
  enabled = false,
  roles = [],
  gustoId = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.PEOPLE}?page=${page}&search=${search}&limit=${limit}${roles?.length > 0 ? `&roles=${roles}` : ''}${
        type ? `&type=${type}` : ''
      }${isNotExpenseReporter ? `&isNotExpenseReporter=true` : ''}${enabled ? `&enabled=${enabled}` : ''}${gustoId ? `&gustoId=${gustoId}` : ''}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getPeopleById = async (id) => {
  try {
    const response = await AxiosInstance.get(`${url?.PEOPLE}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getPeopleWeeklyTime = async (id, weekStart = '') => {
  try {
    const response = await AxiosInstance.get(
      `${url?.PEOPLE}/${id}/weekly-time-entries?weekStart=${weekStart}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updatePeople = async (data, id) => {
  try {
    const response = await AxiosInstance.patch(`${url?.PEOPLE}/${id}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const deletePeople = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.PEOPLE}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const resendInvitePeopleEmail = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.PEOPLE}/resend-invite-email/${id}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Taxes
export const getTaxes = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.TAXES}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createTax = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.TAXES}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateTax = async (data, id) => {
  try {
    const response = await AxiosInstance.patch(`${url?.TAXES}/${id}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteTax = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.TAXES}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Products
export const getProducts = async ({
  limit = 50,
  page = 1,
  search = '',
  isTimeEntry = false,
} = {}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.PRODUCTS}?search=${search}&page=${page}&limit=${limit}${isTimeEntry ? `&isNotExpenseReporter=true` : ''}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getProductHistory = async ({ id, page = 1, limit = 50 }) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.PRODUCTS}/${id}/product-stock-history?page=${page}&limit=${limit}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createProduct = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.PRODUCTS}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateProduct = async (data, id) => {
  try {
    const response = await AxiosInstance.patch(`${url?.PRODUCTS}/${id}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteProduct = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.PRODUCTS}/${id}`);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Product & Services Import

export const productsCsvImport = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.PRODUCTS}${url?.PRODUCTS_PARSE}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const productsImportCreate = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.PRODUCTS}${url?.PRODUCTS_IMPORT}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const productsImportBulk = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.PRODUCTS}${url?.PRODUCTS_IMPORT_BULK}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Automation Templates
export const getAutomationTemplates = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.AUTOMATION_TEMPLATES}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getAutomationTemplateById = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.AUTOMATION_TEMPLATES}/${id}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Automations

export const createAutomation = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.AUTOMATIONS}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getAutomations = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.AUTOMATIONS}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getAutomationById = async (id) => {
  try {
    const response = await AxiosInstance.get(`${url?.AUTOMATIONS}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteAutomation = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.AUTOMATIONS}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const activateAutomation = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.AUTOMATIONS}/${id}/enable-disable`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateAutomation = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.AUTOMATIONS}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Tags

export const getTags = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.GET_TAGS}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createTag = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.GET_TAGS}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createGroupTag = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.GET_TAGS}${url?.TAGS_GROUP}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateTag = async (data, id) => {
  try {
    const response = await AxiosInstance.patch(`${url?.GET_TAGS}/${id}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteTag = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.GET_TAGS}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Assign Transaction to Tag
export const updateTransactionTags = async (transId, data = null) => {
  try {
    const response = await AxiosInstance.put(
      `${url?.GET_TRANSACTIONS}/${transId}${url?.GET_TAGS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Bulk assign transactions to tags
export const bulkUpdateTransactionTags = async (data = null) => {
  try {
    const response = await AxiosInstance.put(
      `${url?.GET_TRANSACTIONS}${url?.GET_TAGS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Delete Transactions By IDs
export const deleteTransactionsByIDs = async (data) => {
  try {
    const response = await AxiosInstance.delete(`${url?.GET_TRANSACTIONS}`, {
      data,
    });
    return response?.data;
  } catch (error) {
    //    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Bulk exclude or unExclude transactions
export const bulkExcludeTransactions = async (data = null) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.GET_TRANSACTIONS}${url?.BULK_EXCLUDE}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Merchants

export const getMerchants = async ({
  limit = 10000,
  page = 1,
  search = '',
  id = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.MERCHANTS}?page=${page}&limit=${limit}&search=${search}&id=${id}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createMerchant = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.MERCHANTS}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateMerchant = async (data, id) => {
  try {
    const response = await AxiosInstance.patch(`${url?.MERCHANTS}/${id}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const uploadMerchantLogo = async (data, id) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.MERCHANTS}/${id}${url?.LOGO}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteMerchant = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.MERCHANTS}/${id}`);
    return response?.data;
  } catch (error) {
    const operationalError = error?.response?.data;
    if (operationalError?.errors?.error?.isOperational === true) {
      throw new Error(
        JSON.stringify({
          message: operationalError.message,
          status: operationalError.status,
        }),
      );
    }
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Dashboard
export const getDashboard = async (year, month) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.DASHBOARD}?year=${year}&month=${month}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getPayableBills = async ({
  startDate = '',
  endDate = '',
  project = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.BILLS}${url?.PAYABLE}?startDate=${startDate}&endDate=${endDate}&project=${project}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getReceivableInvoices = async ({
  startDate = '',
  endDate = '',
  project = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INVOICES}${url?.RECEIVABLE}?startDate=${startDate}&endDate=${endDate}&project=${project}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const approveEstimateBySignature = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.INVOICES}/${id}/sign`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};
// Subscriptions

export const getStripeInstance = async () => {
  try {
    const response = await AxiosInstance.get(
      `${url?.MEMBERS}${url?.SUBSCRIPTION}${url?.STRIPE_INTENT}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const stripeSubsMethod = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.MEMBERS}${url?.SUBSCRIPTION}${url?.STRIPE_SUBSCRIPTION}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getTeamSubscriptions = async (teamId) => {
  try {
    const response = await AxiosInstance.get(`${url?.ACTIVE_SUBSCRIPTION}`, {
      headers: {
        'count-workspace-id': teamId || getTeamId(),
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getTeamSubscriptionHistory = async () => {
  try {
    const response = await AxiosInstance.get(
      `${url?.SUBSCRIPTION}${url?.HISTORY}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Members
export const getMembersApi = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.MEMBERS}/${getTeamId()}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getFirmMembersApi = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.ACCOUNTING_FIRM}/${id}${url?.ACCOUNTING_MEMBERS}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteInvitedMemberApi = async (id) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.MEMBERS}/${getTeamId()}${url?.INVITE}`,
      {
        data: {
          inviteId: id,
        },
      },
    );
    return response?.data;
  } catch (error) {
    return error?.response?.data?.message;
  }
};

export const deleteInvitedFirmApi = async (id) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.MEMBERS}/${getTeamId()}${url?.ACCOUNTING_FIRM}/${id}${url?.ACCOUNTING_FIRM_INVITATION_WITHDRAW}`,
      {
        data: {
          inviteId: id,
        },
      },
    );
    return response?.data;
  } catch (error) {
    return error?.response?.data?.message;
  }
};

export const changeMemberRole = async (userId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.MEMBERS}/${getTeamId()}${url?.UPDATE_USER_ROLE}/${userId}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const addTeamMember = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.MEMBERS}/${getTeamId()}${url?.INVITE}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const addFirmToWorkspace = async (firmId) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.MEMBERS}/${getTeamId()}${url?.ACCOUNTING_FIRM}/${firmId}${url?.INVITE}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const addMemberToFirm = async (firmdId, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.ACCOUNTING_FIRM}/${firmdId}${url?.INVITATION}${url?.INVITE_FIRM_MEMBER}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const withdrawMemberInvitationAPI = async (firmId, invitationId) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.ACCOUNTING_FIRM}/${firmId}${url?.INVITATION}/${invitationId}${url?.WITHDRAW_MEMBER_INVITATION}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// TEAMS

export const getAllTeamsForUser = async ({
  page = 1,
  limit = 20,
  search = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.MEMBERS}?page=${page}&limit=${limit}&search=${search}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getTeamById = async (id) => {
  try {
    const response = await AxiosInstance.get(`${url?.MEMBERS}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getPeopleTeam = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.MEMBERS}/people`);
    return response?.data?.data?.team;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateTeamById = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(`${url?.MEMBERS}/${id}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateTeamDefaultsById = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.MEMBERS}/${id}/update-default`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateTeamDefaults = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.MEMBERS}/${id}/update-default`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const skipLaunchpad = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.MEMBERS}/${id}/launchpad-status`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const acceptTeamInviteApi = async (teamId) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.MEMBERS}/${teamId}${url?.ACCEPT_INVITE}`,
      {},
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const acceptTeamInviteByFirmApi = async (firmId, inviteId) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.ACCOUNTING_FIRM}/${firmId}${url?.INVITATION}${url?.WORKSPACE_INVITE}/${inviteId}${url?.ACCEPT}`,
      {},
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const acceptFirmInviteApi = async (firmId) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.GET_USER}${url?.INVITATION}${url?.ACCOUNTING_FIRM}/${firmId}${url?.ACCEPT_FIRM_INVITE}`,
      {},
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const declineTeamInviteApi = async (teamId) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.MEMBERS}/${teamId}${url?.DECLINE_INVITE}`,
      {},
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const declineTeamInviteByFirmApi = async (firmId, inviteId) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.ACCOUNTING_FIRM}/${firmId}/${url?.INVITATION}${url?.WORKSPACE_INVITE}/${inviteId}${url?.DECLINE}`,
      {},
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const declineFirmInviteApi = async (firmId) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.GET_USER}${url?.INVITATION}${url?.ACCOUNTING_FIRM}/${firmId}${url?.DECLINE_FIRM_INVITE}`,
      {},
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const removeUserFromTeamApi = async (userId) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.MEMBERS}/${getTeamId()}${url?.REMOVE_USER}`,
      {
        data: {
          userId,
        },
      },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const removeFirmFromTeam = async (teamId, firmId) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.MEMBERS}/${teamId}${url?.ACCOUNTING_FIRM}/${firmId}${url?.REMOVE_FIRM_FROM_TEAM}`,
      {},
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const leaveTeamApi = async (teamId) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.MEMBERS}/${teamId}${url?.LEAVE_TEAM}`,
      {},
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const leaveTeamFromFirmApi = async (firmId, teamId) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.ACCOUNTING_FIRM}/${firmId}${url?.LEAVE_WORKSPACE}/${teamId}`,
      {},
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const leaveAccountingFirmApi = async (id) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.GET_USER}${url?.ACCOUNTING_FIRM}/${id}${url?.LEAVE_TEAM}`,
      {},
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const removeMemberFromFirmApi = async (firmId, userId) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.ACCOUNTING_FIRM}/${firmId}${url?.REMOVE_FIRM_MEMBER}/${userId}`,
      {},
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const firmInviteBusinessOwner = async (firmId, body) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.ACCOUNTING_FIRM}/${firmId}/${url?.FIRM_INVITE_BUSINESS_OWNER}`,
      body,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const withdrawFirmInviteBusinessOwner = async (firmId, id) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.ACCOUNTING_FIRM}/${firmId}${url?.FIRM_BUSINESS_OWNER}/${id}${url?.FIRM_INVITE_BUSINESS_OWNER_WITHDRAW}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const teamDeclinefirmInviteBusinessOwner = async (id) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.FIRM_INVITATION_TEAM}/${id}${url?.FIRM_INVITATION_TEAM_DECLINE}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const teamAcceptfirmInviteBusinessOwner = async (id, body) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.FIRM_INVITATION_TEAM}/${id}${url?.FIRM_INVITATION_TEAM_ACCEPT}`,
      body,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getLaunchPadStatus = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.MEMBERS}/${id}${url?.LAUNCHPAD}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Team
export const createTeam = async (values) => {
  try {
    const response = await AxiosInstance.post(`${url?.MEMBERS}`, values);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

export const uploadTeamLogo = async (values, id) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.MEMBERS}/${id}${url?.LOGO}`,
      values,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

export const deleteTeamApi = async (teamId) => {
  try {
    const response = await AxiosInstance.delete(`${url?.MEMBERS}/${teamId}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Bills

// Crete Bill API
export const createBill = async (values) => {
  try {
    const response = await AxiosInstance.post(`${url?.BILLS}`, values);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

// Get All Bills API
export const getAllBills = async ({
  page = 1,
  limit = 50,
  startDate = '',
  endDate = '',
  vendors = [],
  projects = '',
  amount = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.BILLS}?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&vendors=${vendors}&projects=${projects}&amount=${amount}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

// GET Processed Bills Stats
export const getProcessBillsStat = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.GET_PROCESSED_BILL_STAT}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

export const getBillsEvents = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.BILL_EVENTS}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

// Get SIngle Bill API
export const getBillById = async (id) => {
  try {
    const response = await AxiosInstance.get(`${url?.BILLS}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

// Update Bill API
export const updateBillById = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(`${url?.BILLS}/${id}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

// Update Bill API
export const updateBillTags = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.BILLS}/${id}/tags`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

// Delete Bill API
export const deleteBillById = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.BILLS}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

// Assign Transaction To Bill
export const assignTransactionToBill = async (billId, instanceId, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.BILLS}/${billId}/instances/${instanceId}/assign-transaction`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

// Unassign Transaction To Bill
export const unassignTransactionToBill = async (billId, instanceId, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.BILLS}/${billId}/instances/${instanceId}/unassign-transaction`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

// Undate Bill Instance
export const updateInstanceById = async (billId, instanceId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.BILLS}/${billId}/instances/${instanceId}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

// Get Admin Web Hooks
export const getAdminWebHooks = async ({
  page = 1,
  limit = 20,
  teamId = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.WEB_HOOKS}?page=${page}&limit=${limit}&teamId=${teamId}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

// Get Admin Team Logs
export const getTeamLogs = async ({ page = 1, limit = 20 }) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.TEAM_LOGS}?page=${page}&limit=${limit}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

// Admin Institutions
export const getAllInstitutions = async ({
  page = 1,
  limit = 20,
  search = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSTITUTIONS}?page=${page}&limit=${limit}&search=${search}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

export const uploadInstitutionLogo = async (data, id) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.INSTITUTIONS}/${id}${url?.LOGO}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateInstitution = async (data, id) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.INSTITUTIONS}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// STAFF DEFAULT ACCOUNTS
export const getDefaultAccounts = async ({
  page = 1,
  limit = 20,
  type = '',
  subType = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.DEFAULT_ACCOUNTS}?page=${page}&limit=${limit}${
        type && `&type=${type}`
      }${subType && `&subTypeId=${subType}`}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

// Reports
export const generateReports = async ({
  accounts = [],
  startDate = '',
  endDate = '',
  tags = [],
  save,
  reportTitle = '',
  reportType = 'accrual',
  compareWith = {},
  currency = '',
  isPnLByTag = false,
  noTags = false,
}) => {
  try {
    const data = {
      save,
      reportTitle,
      isPnLByTag,
      ...(Object.keys(compareWith).length && { compareWith }),
    };
    const response = await AxiosInstance.post(
      `${url.GENERATE_REPORTS}?accounts=${accounts.join(
        ',',
      )}${startDate ? `&startDate=${startDate}` : ''}${isPnLByTag ? `&isPnLByTag=${isPnLByTag}` : ''}${endDate ? `&endDate=${endDate}` : ''}&tags=${tags.join(
        ',',
      )}&reportType=${reportType}&currency=${currency}&noTags=${noTags}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const generateTimeSheetReports = async ({
  startDate = '',
  endDate = '',
  people = [],
  projectId = [],
  products = [],
  save,
  reportTitle = '',
}) => {
  try {
    const response = await AxiosInstance.post(
      `${url.TIME_SHEET_REPORT}?people=${people?.join?.(
        ',',
      )}&products=${products?.join?.(
        ',',
      )}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}&projectId=${projectId?.join?.(
        ',',
      )}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const reportHistory = async ({ page = 1, limit = 20 }) => {
  try {
    const response = await AxiosInstance.get(
      `${url.REPORT_HISTORY}?page=${page}&limit=${limit}&teamId=${getTeamId()}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteReport = async (reportId) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.REPORT_HISTORY}/${reportId}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createBalanceSheet = async ({
  endDate = '',
  reportType = 'accrual',
  save,
  reportTitle = '',
  currency = '',
}) => {
  try {
    const response = await AxiosInstance.post(
      `${url.BALANCE_SHEETS}?endDate=${endDate}&reportType=${reportType}&currency=${currency}`,
      { save, reportTitle },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createIncomeCustomerReport = async ({
  startDate = '',
  endDate = '',
  save,
  reportTitle = '',
  currency = '',
}) => {
  try {
    const response = await AxiosInstance.post(`${url.CUSTOMER_INCOME}`, {
      save,
      reportTitle,
      startDate,
      endDate,
      currency,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createAgedReceivables = async ({
  endDate = '',
  save,
  reportTitle = '',
  currency = '',
}) => {
  try {
    const response = await AxiosInstance.post(`${url.AGED_RECEIVABLES}`, {
      save,
      reportTitle,
      endDate,
      currency,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createCategoryBreakdown = async ({
  type = '',
  save,
  tags = [],
  currency = '',
  accountIDs = [],
  reportTitle = '',
  year = '',
}) => {
  try {
    const response = await AxiosInstance.post(`${url.CATEGORY_BREAKDOWN}`, {
      type,
      save,
      tags,
      currency,
      accountIDs,
      reportTitle,
      year,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const favoriteReport = async (favoriteReport = []) => {
  try {
    const response = await AxiosInstance.post(`${url.FAVORITE_REPORTS}/add`, {
      favoriteReport,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const removeFavoriteReport = async (removeFavoriteReport = []) => {
  try {
    const response = await AxiosInstance.delete(
      `${url.FAVORITE_REPORTS}/remove`,
      { data: { removeFavoriteReport } },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getFavoriteReports = async () => {
  try {
    const response = await AxiosInstance.get(`${url.FAVORITE_REPORTS}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// AI

export const askAI = async (id, data) => {
  try {
    const response = await AxiosInstance.post(`${url?.AI}/${id}/ask`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getAIChats = async (id) => {
  try {
    const response = await AxiosInstance.get(`${url?.AI}/${id}/chats`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteAIChat = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.AI}/${id}/reset`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Insights
export const getSpendingBreakdown = async ({
  startDate = '',
  endDate = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSIGHTS}${url?.SPENDING}/breakdown?startDate=${startDate}&endDate=${endDate}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getTopSpendingVendors = async ({
  startDate = '',
  endDate = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSIGHTS}${url?.SPENDING}/top-vendors?startDate=${startDate}&endDate=${endDate}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getMonthlySpending = async ({ startDate = '', endDate = '' }) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSIGHTS}${url?.SPENDING}/by-month?startDate=${startDate}&endDate=${endDate}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getTopSpendingCategories = async ({
  startDate = '',
  endDate = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSIGHTS}${url?.SPENDING}/by-category?startDate=${startDate}&endDate=${endDate}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getSpendingBySingleCategory = async ({
  startDate = '',
  endDate = '',
  id,
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSIGHTS}${url?.SPENDING}/by-category/${id}?startDate=${startDate}&endDate=${endDate}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getSpendingByVendor = async ({ startDate = '', endDate = '' }) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSIGHTS}${url?.SPENDING}/by-vendor?startDate=${startDate}&endDate=${endDate}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getTopIncomeCategories = async ({
  startDate = '',
  endDate = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSIGHTS}${url?.INCOME}/by-category?startDate=${startDate}&endDate=${endDate}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getIncomeBySingleCategory = async ({
  startDate = '',
  endDate = '',
  id,
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSIGHTS}${url?.INCOME}/by-category/${id}?startDate=${startDate}&endDate=${endDate}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getMonthlyIncome = async ({ startDate = '', endDate = '' }) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSIGHTS}${url?.INCOME}/by-month?startDate=${startDate}&endDate=${endDate}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getIncomeBreakdown = async ({ startDate = '', endDate = '' }) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSIGHTS}${url?.INCOME}/breakdown?startDate=${startDate}&endDate=${endDate}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getProjectTransactionSummary = async (projectId = '') => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSIGHTS}${url?.GET_TRANSACTIONS}${url?.PROJECT_SUMMARY}/${projectId}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Akahu testing

export const akahuTokenExchange = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `/providers/akahu/token/exchange`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Gusto Integration

export const gustoTokenExchange = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `/app-integrations/exchange-token`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const generateAccountTransactions = async ({
  accounts = [],
  startDate = '',
  endDate = '',
  save,
  reportTitle = '',
  currency = '',
  tags = [],
  vendors = [],
  projects = [],
  customers = [],
  tax = '',
}) => {
  try {
    const account = `&categoryAccount=${accounts.join(',')}`;

    const response = await AxiosInstance.post(
      `${
        url.GENERATE_ACCOUNT_TRANSACTION
      }?${startDate && `startDate=${startDate}&`}endDate=${endDate}${
        save ? `${accounts?.length > 0 ? account : ''}` : account
      }&currency=${currency}${tags?.length > 0 ? `&tags=${tags}` : ''}${tax?.length > 0 ? `&tax=${tax}` : ''}${customers?.length > 0 ? `&customers=${customers}` : ''}${vendors?.length > 0 ? `&vendors=${vendors}` : ''}${projects?.length > 0 ? `&projects=${projects}` : ''}`,
      { save, reportTitle },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const generateSalesTaxReport = async ({
  accounts = [],
  startDate = '',
  endDate = '',
  save,
  reportTitle = '',
  currency = '',
  reportType = '',
}) => {
  try {
    const account = `&accounts=${accounts.join(',')}`;

    const response = await AxiosInstance.post(
      `${
        url.GENERATE_SALES_TAX_REPORT
      }?${startDate && `startDate=${startDate}&`}endDate=${endDate}${
        save ? `${accounts?.length > 0 ? account : ''}` : account
      }&currency=${currency}&reportType=${reportType}`,
      { save, reportTitle },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const generateIncomeTaxableNontaxableReport = async ({
  accounts = [],
  startDate = '',
  endDate = '',
  save,
  reportTitle = '',
  currency = '',
  reportType = '',
}) => {
  try {
    const account = `&accounts=${accounts.join(',')}`;

    const response = await AxiosInstance.post(
      `${
        url.GENERATE_TAXABLE_NON_TAXABLE_INCOME_REPORT
      }?${startDate && `startDate=${startDate}&`}endDate=${endDate}${
        save ? `${accounts?.length > 0 ? account : ''}` : account
      }&currency=${currency}&reportType=${reportType}`,
      { save, reportTitle },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const generateInvoicesBySalesRep = async ({
  startDate = '',
  endDate = '',
  save,
  reportTitle = '',
  currency = '',
} = {}) => {
  try {
    const response = await AxiosInstance.post(
      `${
        url.GENERATE_INVOICES_BY_SALES_REP
      }?${startDate && `startDate=${startDate}&`}endDate=${endDate}&currency=${currency}`,
      { save, reportTitle },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const generateExpenseReporterReport = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url.EXPENSE_REPORTER_REPORT}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// JOURNAL ENTRIES

export const getJournalEntries = async ({
  limit = 1000,
  page = 1,
  search = '',
  startDate = '',
  endDate = '',
  accounts = '',
  accountType = '',
  reviewed = '',
  reconciled = '',
  projects = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.JOURNAL_ENTRIES}?page=${page}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}&accounts=${accounts}&accountType=${accountType}&reviewed=${reviewed}&reconciled=${reconciled}&projects=${projects}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getJournalEntry = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.JOURNAL_ENTRIES}/${id}/linked-journal-entries`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createJournalEntries = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.JOURNAL_ENTRIES}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateJournalEntry = async (data, id) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.JOURNAL_ENTRIES}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateJournalEntryTags = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.JOURNAL_ENTRIES}/${id}/tags`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const changeJournalEntryCategory = async (id, data = {}) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.JOURNAL_ENTRIES}/${id}/change-category`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const reviewJournalEntry = async (id, data = {}) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.JOURNAL_ENTRIES}/${id}/toggle-review`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const bulkUpdateReviewJournalEntry = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.JOURNAL_ENTRIES}/bulk-review`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const uploadJournalEntryLogo = async (data, id) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.JOURNAL_ENTRIES}/${id}${url?.LOGO}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteJournalEntry = async (id) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.JOURNAL_ENTRIES}/${id}`,
    );
    return response?.data;
  } catch (error) {
    const operationalError = error?.response?.data;
    if (operationalError?.errors?.error?.isOperational === true) {
      throw new Error(
        JSON.stringify({
          message: operationalError.message,
          status: operationalError.status,
        }),
      );
    }
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Invoices
export const getInvoices = async ({
  limit = 1000,
  page = 1,
  type = 'invoice',
  customers = [],
  status = [],
  startDate = '',
  endDate = '',
  search = '',
  projects = '',
  currency = '',
  isStatment = false,
  isRecurring = false,
  amount = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${
        url?.INVOICES
      }?page=${page}&limit=${limit}&invoiceType=${type}&search=${search}&status=${status}&startDate=${startDate}&endDate=${
        endDate || startDate
      }&customers=${customers}&projects=${projects}&currency=${currency}&isStatment=${isStatment}&isRecurring=${isRecurring}&amount=${amount}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getRecurringTemplates = async () => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INVOICES}/recurring-invoice-template`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateRecurringTemplates = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.INVOICES}/${id}/recurring-invoice-template`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteRecurringTemplates = async (id) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.INVOICES}/${id}/recurring-invoice-template`,
    );
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createInvoice = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.INVOICES}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const uploadInvoiceDoc = async (id, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.INVOICES}/${id}${url?.ATTACHMENTS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const uploadInvoiceDefaultDoc = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.INVOICES}/default-attachments`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const getInvoiceDefaultDocs = async () => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INVOICES}/default-attachments/all`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateInvoiceDefaultDocs = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.INVOICES}/default-attachments/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteInvoiceDefaultDocs = async (id) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.INVOICES}/default-attachments/${id}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const uploadInvoiceLogo = async (values, id) => {
  try {
    const response = await AxiosInstance.put(
      `${url?.INVOICES}/${id}${url?.CUSTOMIZE}`,
      values,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message || 'error';
  }
};

export const deleteInvoiceAttachment = async (invoiceId, receiptId) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.INVOICES}/${invoiceId}${url?.ATTACHMENTS}/${receiptId}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateInvoiceAttachment = async (invoiceId, receiptId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.INVOICES}/${invoiceId}${url?.ATTACHMENTS}/${receiptId}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateInvoice = async (data, id) => {
  try {
    const response = await AxiosInstance.patch(`${url?.INVOICES}/${id}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateInvoiceTags = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.INVOICES}/${id}/tags`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getInvoiceById = async (invoiceId) => {
  try {
    const response = await AxiosInstance.get(`${url?.INVOICES}/${invoiceId}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getPublicInvoiceById = async (invoiceId) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INVOICES}/${invoiceId}${url?.PUBLIC}`,
    );
    return response?.data;
  } catch (error) {
    // toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const deleteInvoice = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.INVOICES}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getInvoiceIntent = async (id, method = '') => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INVOICES}/${id}/payment-intent?paymentMethod=${method}`,
    );
    return response?.data;
  } catch (error) {
    // toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Assign Transaction To Bill
export const assignInvoiceToBill = async (invoiceId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.INVOICES}/${invoiceId}/add-transactions`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

export const deAssignTransToInvoice = async (invoiceId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.INVOICES}/${invoiceId}/remove-transaction`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

export const sendInvoiceEmail = async (invoiceId, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.INVOICES}/${invoiceId}/send`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

export const generateInvoiceNumber = async () => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INVOICES}/generate/number`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const generateStripeToConnect = async () => {
  const _teamId = getTeamId();
  try {
    const response = await AxiosInstance.get(
      `${url?.MEMBERS}/${_teamId}/${url?.STRIPE_CONNECT_LINK}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const generateStripeLogin = async () => {
  const _teamId = getTeamId();
  try {
    const response = await AxiosInstance.get(
      `${url?.MEMBERS}/${_teamId}/${url?.STRIPE_LOGIN_LINK}`,
    );
    return response?.data;
  } catch (error) {
    // toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const generateStripeDetails = async () => {
  const _teamId = getTeamId();
  try {
    const response = await AxiosInstance.get(
      `${url?.MEMBERS}/${_teamId}/${url?.STRIPE_ACCOUNT_DETAILS}`,
    );
    return response?.data;
  } catch (error) {
    // toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const enrichPlayground = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.ENRICH_PLAYGROUND}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

// Internal Vendors

export const getInternalVendors = async ({ limit = 10, page = 1 }) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INTERNAL_VENDORS}?page=${page}&limit=${limit}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createInternalVendor = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.INTERNAL_VENDORS}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const updateInternalVendor = async (data, id) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.INTERNAL_VENDORS}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const uploadInternalVendorLogo = async (data, id) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.INTERNAL_VENDORS}/${id}/logo`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const deleteInternalVendor = async (id) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.INTERNAL_VENDORS}/${id}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

// Vendors Import

export const vendorsCsvImport = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.MERCHANTS}${url?.VENDOR_PARSE}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const vendorsImportCreate = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.MERCHANTS}${url?.VENDOR_IMPORT}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Inbox

// Task Attachments

export const uploadTaskAttachment = async (id, data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.TASK}/${id}/attachments`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getTaskAttachments = async (id) => {
  try {
    const response = await AxiosInstance.get(`${url?.TASK}/${id}/attachments`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const deleteTaskAttachment = async (taskId, attachmentId) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.TASK}/${taskId}/attachments/${attachmentId}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getInboxCount = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.INBOX}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const createTask = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.TASK}${url?.CREATE}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateTask = async (id, data) => {
  try {
    const response = await AxiosInstance.put(`${url?.TASK}/${id}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const deleteTask = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.TASK}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getTaskById = async (id) => {
  try {
    const response = await AxiosInstance.get(`${url?.TASK}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getTasks = async ({
  page = 1,
  limit = 50,
  assigned = '',
  status = '',
  priority = '',
  orderBy = '',
  orderDirection = '',
  search = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.TASK}/team?page=${page}&limit=${limit}&assigned=${assigned}&status=${status}&priority=${priority}&orderBy=${orderBy}&orderDirection=${orderDirection}&search=${search}`, //
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getNotifications = async ({ page = 1, limit = 50 }) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.NOTIFICATION}/?page=${page}&limit=${limit}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const markNotificationAsRead = async (id) => {
  try {
    const response = await AxiosInstance.put(
      `${url?.NOTIFICATION}${url?.MARK_SEEN}/${id}`,
      { seen: true },
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const deleteNotification = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.NOTIFICATION}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Audit Log
export const getAuditLog = async ({ page = 1, limit = 50 }) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.GET_TRANSACTIONS}${url?.AUDIT_LOGS}?page=${page}&limit=${limit}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Get Users Country
export const getUserCountry = async () => {
  try {
    const response = await axios.get('https://api.country.is/');
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// EXPENSE REPORTING

export const startExpenseTypeImport = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.EXPENSE_REPORTING}/types/start-setup`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getExpenseReporterTrans = async ({
  type = '',
  page = 1,
  limit = 50,
  accounts = [],
  projects = [],
  tags = [],
  expenseTypes = [],
  reporters = [],
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.EXPENSE_REPORTING}?type=${type}&page=${page}&limit=${limit}&accounts=${accounts}&projects=${projects}&tags=${tags}&expenseTypes=${expenseTypes}&reporters=${reporters}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const bulkUpdateExpenseReporting = async (data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.EXPENSE_REPORTING}/bulk-update-status`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateExpenseReport = async (id, data) => {
  try {
    const response = await AxiosInstance.put(
      `${url?.EXPENSE_REPORTING}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getExpenseReporters = async ({
  page = 1,
  limit = 50,
  createdByMe = '',
  search = '',
  type = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url.PEOPLE}${url?.EXPENSE_REPORTERS}?page=${page}&limit=${limit}&createdByMe=${createdByMe}&search=${search}&type=${type}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const createExpenseReporter = async (peopleId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url.PEOPLE}${url?.ADD_EXPENSE_REPORTER}/${peopleId}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const deleteExpenseReporter = async (peopleId) => {
  try {
    const response = await AxiosInstance.patch(
      `${url.PEOPLE}${url?.REMOVE_EXPENSE_REPORTER}/${peopleId}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateExpenseReportStatus = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.EXPENSE_REPORTING}/update-status/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const importExpenseTypes = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.EXPENSE_REPORTING}/types/save-setup`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const createExpenseType = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.EXPENSE_REPORTING}/types`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getExpenseTypes = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.EXPENSE_REPORTING}/types`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateExpenseType = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.EXPENSE_REPORTING}/types/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const deleteExpenseType = async (id) => {
  try {
    const response = await AxiosInstance.delete(
      `${url?.EXPENSE_REPORTING}/types/${id}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Time
export const createTimeEntry = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.TIME_ENTRIES}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getTimeInsights = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INSIGHTS}${url?.TIME_ENTRIES}${url?.PROJECT_SUMMARY}/${id}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getAllTimeEntries = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.TIME_ENTRIES}?projects=${id}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const deleteTimeEntry = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.TIME_ENTRIES}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateTimeEntry = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.TIME_ENTRIES}/${id}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Projects
export const createProject = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.PROJECTS}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateProject = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(`${url?.PROJECTS}/${id}`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const deleteProject = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.PROJECTS}/${id}`);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getAllProjects = async ({
  search = '',
  customers = [],
  status = [],
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.PROJECTS}?search=${search}&customers=${customers}&status=${status}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getSingleProject = async (id) => {
  try {
    const response = await AxiosInstance.get(`${url?.PROJECTS}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getSingleProjectStats = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.PROJECTS}/${id}${url?.PROJECT_STAT}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Recurring Transactions

export const getRecurringTransactions = async () => {
  try {
    const response = await AxiosInstance.get(
      `${url?.GET_TRANSACTIONS}/recurring`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Uploading Bills

export const uploadBillPDF = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.FILES}${url?.INVOICES}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Customer Statement

export const getCustomerInvoice = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.INVOICES}/statments/${id}`,
    );
    return response?.data;
  } catch (error) {
    // toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// App Integration Gusto

export const getAppIntegrations = async ({ app = '' }) => {
  try {
    const response = await AxiosInstance.get(`${url?.APP_INTEGRATION}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const syncGustoEmployee = async (appId, departmentId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `${url?.APP_INTEGRATION}/gusto/${appId}/departments/${departmentId}/sync-employees`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getGustoById = async (appId) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.APP_INTEGRATION}/gusto/${appId}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const prepareGustoPayroll = async (gustoId, payrollId) => {
  try {
    const response = await AxiosInstance.put(
      `${url?.APP_INTEGRATION}/gusto/${gustoId}/upcoming-payrolls/${payrollId}/prepare`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const calculateGustoPayroll = async (gustoId, payrollUuid, data) => {
  try {
    const response = await AxiosInstance.put(
      `${url?.APP_INTEGRATION}/gusto/${gustoId}/upcoming-payrolls/${payrollUuid}/calculate`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const updateGustoPayroll = async (gustoId, payrollUuid, data) => {
  try {
    const response = await AxiosInstance.put(
      `${url?.APP_INTEGRATION}/gusto/${gustoId}/upcoming-payrolls/${payrollUuid}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const submitGustoPayroll = async (gustoId, payrollUuid) => {
  try {
    const response = await AxiosInstance.put(
      `${url?.APP_INTEGRATION}/gusto/${gustoId}/upcoming-payrolls/${payrollUuid}/submit`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getGustoPayrollById = async (gustoId, payrollUuid) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.APP_INTEGRATION}/gusto/${gustoId}/upcoming-payrolls/${payrollUuid}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getPayrolls = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.PAYROLLS}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getGustoUpcomingPayroll = async (appId) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.APP_INTEGRATION}/gusto/${appId}/upcoming-payrolls`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

// Quick Book Integration
export const quickBookAuth = async ({ isOnboarding = false } = {}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.QUICK_BOOK_AUTH}?isOnboarding=${isOnboarding}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const quickBookStatus = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.QUICK_BOOK_STATUS}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const importQuickBookAccounts = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.QUICK_BOOK_ACCOUNTS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

export const importQuickBookTransactions = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.QUICK_BOOK_TRANSACTIONS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

export const importQuickBookCustomers = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.QUICK_BOOK_CUSTOMERS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

export const importQuickBookVendors = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.QUICK_BOOK_VENDORS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

export const importQuickBookProducts = async (data) => {
  try {
    const response = await AxiosInstance.post(
      `${url?.QUICK_BOOK_PRODUCTS}`,
      data,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

export const fetchSubscriptions = async (country = '') => {
  try {
    const response = await AxiosInstance.get(
      `${url?.SUBSCRIPTIONS}${country ? `?country=${country}` : ''}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

export const getTeamStats = async (teamId) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.MEMBERS}/${teamId}${url?.TEAM_STATS}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

export const subscriptionCheckout = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.SUBSCRIPTION_CHECKOUT}/${id}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

export const createSubPaymentMethod = async (redirectUrl) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.SUBSCRIPTION_PAYMENT_METHOD}?redirectUrl=${redirectUrl}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message || 'error';
  }
};

export const searchAddressAPI = async (query) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/place/textsearch/json`,
      {
        params: {
          query,
          key: process.env.REACT_APP_MAPS_KEY,
        },
      },
    );
    console.log('response', response);
    return response?.data?.results;
  } catch (error) {
    console.error('Error fetching the places data:', error);
  }
};

export const getAccountingFirmTasks = async ({
  firmId = '',
  page = 1,
  limit = 50,
  assigned = '',
  status = '',
  priority = '',
  orderBy = '',
  orderDirection = '',
  search = '',
  clients = '',
}) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.TASK}${url.ACCOUNTING_FIRM}?firm-id=${firmId}&page=${page}&limit=${limit}&assigned=${assigned}&status=${status}&priority=${priority}&orderBy=${orderBy}&orderDirection=${orderDirection}&search=${search}&clients=${clients}`, //
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getFirmClients = async (firmId) => {
  try {
    const response = await AxiosInstance.get(
      `${url?.ACCOUNTING_FIRM}/${firmId}${url?.ACCOUNTING_FIRM_CLIENT}`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const createAccountingFirmTasks = async (data) => {
  try {
    const response = await AxiosInstance.post(`${url?.TASK}/create`, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const deleteAccountingFirmTasks = async (id) => {
  try {
    const response = await AxiosInstance.delete(`${url?.TASK}/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getPermissions = async () => {
  try {
    const response = await AxiosInstance.get(`${url?.PERMISSIONS}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

export const getAppVersion = async () => {
  try {
    const response = await AxiosInstance.get(
      `${url?.RELEASE_HISTORY}/latest?appType=web`,
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error?.response?.data?.message;
  }
};

import React, { useEffect, useRef, useState } from 'react';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import Transition from '../../utils/Transition';
import { IS_PERSON } from '../../utils/Utils';
import useClickOutside from '../../utils/useClickOutside';

const ActionsDropdown = ({
  type = 'invoice',
  onChange,
  obj,
  isDraft = false,
  bottomOfTable = false,
  ids = [],
}) => {
  const IsPerson = localStorage.getItem(IS_PERSON)
    ? JSON.parse(localStorage.getItem(IS_PERSON))
    : null;
  const { accessType, permissions } = useSelector((state) => state.User);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fixedDropdownPositions, setFixedDropdownPositions] = useState({});

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const dropdownContainer = useRef(null);

  useClickOutside(dropdownContainer, () => {
    setDropdownOpen(false);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const getFixedPositionValues = () => {
    const { x, y, height } = trigger.current?.getBoundingClientRect();
    const dropdownDimensions = dropdown.current?.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const values = {
      left:
        x + dropdownDimensions?.width > windowWidth
          ? windowWidth - dropdownDimensions?.width - 20
          : x,
      top:
        y + height + dropdownDimensions?.height > windowHeight
          ? windowHeight - dropdownDimensions?.height - 20
          : y + height,
    };
    setFixedDropdownPositions(values);
  };

  useEffect(() => {
    if (bottomOfTable) {
      if (dropdownOpen) {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            ele.style.overflow = 'hidden';
          }
        });
      } else {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            ele.style.overflow = 'auto';
          }
        });
      }
      getFixedPositionValues();
      return () => {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            ele.style.overflow = 'auto';
          }
        });
      };
    }
  }, [dropdownOpen]);

  const getEstimateOptions = (estimate) => {
    const options = [
      {
        id: 'view',
        period: 'View',
      },
      {
        id: 'delete',
        period: 'Delete',
      },
    ];

    if (
      estimate?.isDraft ||
      (estimate?.id &&
        estimate?.paymentStatus !== 'paid' &&
        !(
          estimate?.invoiceType === 'estimate' &&
          estimate?.paymentStatus === 'accepted'
        ))
    ) {
      options.push({
        id: 'edit',
        period: 'Edit',
      });
    }
    if (
      estimate?.isDraft &&
      checkPermission(PermissionKeys.APPROVE_INVOICES, accessType, permissions)
    ) {
      options.push({
        id: 'approveToInvoice',
        period: 'Approve Estimate',
      });
    }
    if (
      !IsPerson &&
      estimate?.paymentStatus !== 'paid' &&
      !estimate?.isDraft &&
      estimate?.invoiceType === 'estimate'
    ) {
      options.push({
        id: 'convertToInvoice',
        period: 'Convert to Invoice',
      });
    }
    if (
      estimate?.paymentStatus !== 'paid' &&
      estimate?.paymentStatus !== 'accepted' &&
      !estimate?.isDraft
    ) {
      options.push({
        id: 'sendToCustomer',
        period: 'Send to customer',
      });
    }
    return options;
  };

  const getInvoiceOptions = (invoice) => {
    const options = [
      {
        id: 'view',
        period: 'View',
      },

      {
        id: 'delete',
        period: 'Delete',
      },
    ];
    if (
      invoice?.isDraft ||
      (invoice?.id &&
        invoice?.paymentStatus !== 'paid' &&
        !(
          invoice?.invoiceType === 'estimate' &&
          invoice?.paymentStatus === 'accepted'
        ))
    ) {
      options.push({
        id: 'edit',
        period: 'Edit',
      });
    }
    if (
      invoice?.isDraft &&
      checkPermission(PermissionKeys.APPROVE_INVOICES, accessType, permissions)
    ) {
      options.push({
        id: 'approveToInvoice',
        period: 'Approve Invoice',
      });
    }
    if (
      checkPermission(
        PermissionKeys.APPROVE_INVOICES,
        accessType,
        permissions,
      ) &&
      !IsPerson &&
      invoice?.paymentStatus !== 'paid' &&
      !invoice?.isDraft &&
      invoice?.invoiceType !== 'estimate'
    ) {
      options.push({
        id: 'recordAPayment',
        period: 'Record a Payment',
      });
    }
    if (
      invoice?.paymentStatus !== 'paid' &&
      invoice?.paymentStatus !== 'accepted' &&
      !invoice?.isDraft
    ) {
      options.push({
        id: 'sendToCustomer',
        period: 'Send to customer',
      });
    }

    return options;
  };

  return (
    <div className={`relative inline-flex `} ref={dropdownContainer}>
      <button
        ref={trigger}
        className={`border  h-[26px] w-[26px] border-slate-300 rounded-md p-1 inline-flex justify-center items-center ${
          dropdownOpen ? '!border-[#78BD4F66] bg-[#78BD4F1A]' : ''
        }`}
        aria-haspopup="true"
        onClick={(e) => {
          e.stopPropagation();
          setDropdownOpen(!dropdownOpen);
        }}
        aria-expanded={dropdownOpen}
        type="button"
      >
        <span className="sr-only">Menu</span>
        <svg
          width="4"
          height="12"
          viewBox="0 0 4 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`stroke-slate-600 text-slate-600 h-3 w-1 ${
            dropdownOpen ? '!stroke-[#78BD4F] !text-[#78BD4F]' : ''
          }`}
        >
          <path
            d="M2.00016 6.63525C2.35082 6.63525 2.63509 6.35099 2.63509 6.00033C2.63509 5.64966 2.35082 5.3654 2.00016 5.3654C1.6495 5.3654 1.36523 5.64966 1.36523 6.00033C1.36523 6.35099 1.6495 6.63525 2.00016 6.63525Z"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="stroke-current"
          />
          <path
            d="M2.00016 2.19076C2.35082 2.19076 2.63509 1.90649 2.63509 1.55583C2.63509 1.20517 2.35082 0.920898 2.00016 0.920898C1.6495 0.920898 1.36523 1.20517 1.36523 1.55583C1.36523 1.90649 1.6495 2.19076 2.00016 2.19076Z"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="stroke-current"
          />
          <path
            d="M2.00016 11.0798C2.35082 11.0798 2.63509 10.7955 2.63509 10.4448C2.63509 10.0942 2.35082 9.8099 2.00016 9.8099C1.6495 9.8099 1.36523 10.0942 1.36523 10.4448C1.36523 10.7955 1.6495 11.0798 2.00016 11.0798Z"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="stroke-current"
          />
        </svg>
      </button>

      <Transition
        show={dropdownOpen}
        tag="div"
        className={`
          ${
            bottomOfTable
              ? 'fixed !w-fit min-w-40 '
              : 'z-10 w-fit absolute top-full right-0'
          }
           bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1`}
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        style={
          bottomOfTable
            ? {
                top: fixedDropdownPositions?.top,
                left: fixedDropdownPositions?.left,
                zIndex: '1000',
              }
            : {}
        }
      >
        <div
          ref={dropdown}
          className="font-medium text-sm text-slate-600 dark:text-slate-300"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {type === 'invoice' &&
            getInvoiceOptions(obj)?.map((option) => (
              <button
                key={option.id}
                tabIndex="0"
                className="flex whitespace-nowrap items-center w-full disabled:cursor-not-allowed hover:bg-slate-50 hover:dark:bg-slate-700/20 py-1 px-3 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(option, obj);
                  setDropdownOpen(false);
                }}
                type="button"
              >
                <span>{option.period}</span>
              </button>
            ))}
          {type === 'estimate' &&
            getEstimateOptions(obj)?.map((option) => (
              <button
                key={option.id}
                tabIndex="0"
                className="flex whitespace-nowrap items-center w-full disabled:cursor-not-allowed hover:bg-slate-50 hover:dark:bg-slate-700/20 py-1 px-3 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(option, obj);
                  setDropdownOpen(false);
                }}
                type="button"
              >
                <span>{option.period}</span>
              </button>
            ))}{' '}
        </div>
      </Transition>
    </div>
  );
};

export default ActionsDropdown;

/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { Form, Formik, useFormikContext } from 'formik';
import ModalBasic from '../../components/ModalBasic';
import DropdownFull from '../../components/DropdownFull';
import DatePickerDash from '../../components/DatePickerDash';
import { Loader } from '../../components/Svg';
import {
  formatDateLocally,
  getCurrencySymbolFromIso,
  getFormattedDate,
  TEAM_DATE_FORMAT,
} from '../../utils/Utils';

const AddTimeModal = ({
  modalOpen,
  setModalOpen,
  people,
  products,
  project,
  projects,
  handleCreateTimeEntry,
  activeEntry,
  setActiveEntry,
  handleUpdateTimeEntry,
  productModalOpen,
  setProductModalOpen,
  peopleModalOpen,
  setPeopleModalOpen,
  currencies,
  createdProduct,
  setCreatedProduct,
  createdPeople,
  setCreatedPeople,
}) => {
  const formikRef = useRef();
  const { team } = useSelector((state) => state.Team);
  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [noClick, setNoClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [peopleChanged, setPeopleChanged] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: false,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      maxDate: new Date(),
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onOpen: function (selectedDates, dateStr, instance) {
        setNoClick(true);
      },
      onClose: function (selectedDates, dateStr, instance) {
        setNoClick(false);
      },
    }),
    [team],
  );

  useEffect(() => {
    if (modalOpen && (productModalOpen || peopleModalOpen)) {
      setNoClick(true);
    } else if (!productModalOpen && !peopleModalOpen && modalOpen) {
      setNoClick(false);
    }
  }, [productModalOpen, peopleModalOpen]);

  useEffect(() => {
    if (!modalOpen) {
      formikRef.current.setValues({
        projectId: project?.id,
        peopleId: '',
        productServiceId: '',
        isBillable: 'true',
        date: formatDateLocally(new Date(), teamDateFormat),
        hours: 0,
        minutes: 0,
        notes: '',
      });
      setActiveEntry(null);
      setCreatedPeople(null);
      setCreatedProduct(null);
      setSelectedProduct(null);
      setPeopleChanged(false);
    }
  }, [modalOpen, team]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const data = !activeEntry
      ? {
          projectId: project?.id || values?.projectId,
          peopleId: values.peopleId,
          productServiceId: values.productServiceId,
          timeEntries: [
            {
              date: getFormattedDate(values.date, teamDateFormat),
              minutes: parseInt(values.hours) * 60 + parseInt(values.minutes),
              isBillable: values.isBillable === 'true' ? true : false,
              notes: values.notes,
            },
          ],
        }
      : {
          id: activeEntry.id,
          minutes: parseInt(values.hours) * 60 + parseInt(values.minutes),
          date: getFormattedDate(values.date, teamDateFormat),
          isBillable: values.isBillable === 'true' ? true : false,
          peopleId: values.peopleId,
          productServiceId: values.productServiceId,
          projectId: project?.id || values?.projectId,
          notes: values.notes,
        };
    if (!activeEntry || activeEntry === null) {
      await handleCreateTimeEntry(data);
    } else {
      await handleUpdateTimeEntry(data);
    }
    formikRef.current.resetForm();
    setModalOpen(false);
    setIsLoading(false);
  };

  return (
    <ModalBasic
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={activeEntry?.id ? 'Edit Time Entry' : 'Add Time Entry'}
      noClick={noClick}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          projectId: project?.id || '',
          peopleId: createdPeople
            ? createdPeople?.id
            : activeEntry?.peopleId
              ? activeEntry.peopleId
              : '',
          productServiceId: createdProduct
            ? createdProduct?.id
            : activeEntry?.productServiceId
              ? activeEntry.productServiceId
              : '',
          isBillable: activeEntry
            ? activeEntry?.isBillable === true
              ? 'true'
              : 'false'
            : 'true',
          date: formatDateLocally(
            activeEntry?.date?.split?.('T')?.[0] || new Date(),
            teamDateFormat,
          ),
          hours: activeEntry?.minutes
            ? Math.floor(activeEntry?.minutes / 60)
            : 0,
          minutes: activeEntry?.minutes ? activeEntry?.minutes % 60 : 0,
          notes: activeEntry?.notes ? activeEntry.notes : '',
        }}
        validationSchema={Yup.object({
          peopleId: Yup.string().required('Person is Required'),
          productServiceId: Yup.string().required('Service is Required'),
          isBillable: Yup.boolean().required('Required'),
          date: Yup.string().required('Date is Required'),
          hours: Yup.number()
            .required('Hours are Required')
            .min(0, 'Hours cannot be negative')
            .integer('Hours must be a whole number (like 1, 5, or 20)'),
          minutes: Yup.number()
            .required('Minutes are Required')
            .min(0, 'Minutes cannot be negative')
            .max(59, 'Minutes can be up to 59')
            .integer('Minutes must be a whole number (like 1, 5, or 20)'),
          notes: Yup.string(),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => {
          useEffect(() => {
            if (createdProduct) {
              validation.setFieldValue('productServiceId', createdProduct.id);
            }
            if (createdPeople) {
              validation.setFieldValue('peopleId', createdPeople.id);
            }
          }, [createdProduct, createdPeople]);

          return (
            <Form>
              <>
                <div className="w-full px-[30px] py-4 border-b border-[#D0D5DD]">
                  <div className="flex flex-col gap-2.5 w-full border-b border-[#D0D5DD] pb-4">
                    <div className="flex flex-col gap-2.5">
                      <label htmlFor="projectId">
                        Project <span className="text-rose-400">*</span>
                      </label>
                      <DropdownFull
                        disabled={project?.id}
                        selected={project?.id || validation.values.projectId}
                        setSelected={(value) => {
                          validation.setFieldValue('projectId', value);
                        }}
                        options={projects}
                        name="name"
                      />
                    </div>
                    <div className="w-full flex gap-2.5">
                      <div className="flex flex-col gap-2.5 w-1/2">
                        <label htmlFor="productServiceId">
                          Service <span className="text-rose-400">*</span>
                        </label>
                        <DropdownFull
                          options={products}
                          name="name"
                          buttonTextClass="truncate"
                          selected={validation.values.productServiceId}
                          setSelected={(value) => {
                            validation.setFieldValue('productServiceId', value);
                          }}
                          addNewOptionButton
                          addNewOptionLabel="Add Service"
                          handleNewOptionButton={() =>
                            setProductModalOpen(true)
                          }
                        />
                        {validation.touched.productServiceId &&
                        validation.errors.productServiceId ? (
                          <div className="text-xs text-rose-400">
                            {validation.errors.productServiceId}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex flex-col gap-2.5 w-1/2">
                        <label htmlFor="peopleId">
                          Person <span className="text-rose-400">*</span>
                        </label>
                        <DropdownFull
                          options={people}
                          name="name"
                          selected={validation.values.peopleId}
                          setSelected={(value) => {
                            validation.setFieldValue('peopleId', value);
                          }}
                          users
                          addNewOptionButton
                          addNewOptionLabel="Add Person"
                          handleNewOptionButton={() => setPeopleModalOpen(true)}
                          manualOption={
                            activeEntry?.people?.id &&
                            validation.values.peopleId ===
                              activeEntry?.people?.id &&
                            !people.some(
                              (person) => person.id === activeEntry.people.id,
                            ) ? (
                              <>
                                <span className="flex gap-2 items-center text-[16px]">
                                  {activeEntry?.people?.avatarUrl ? (
                                    <img
                                      src={activeEntry?.people?.avatarUrl}
                                      alt="avatar"
                                      className="w-8 h-8 rounded-[42px]"
                                    />
                                  ) : (
                                    <div className="h-8 w-8 rounded-[42px] bg-slate-400 text-white text-md flex items-center justify-center">
                                      {activeEntry?.people?.firstName
                                        .charAt(0)
                                        .toUpperCase() +
                                        activeEntry?.people?.lastName
                                          .charAt(0)
                                          .toUpperCase()}
                                    </div>
                                  )}
                                  {activeEntry?.people?.firstName +
                                    ' ' +
                                    activeEntry?.people?.lastName}
                                </span>
                              </>
                            ) : null
                          }
                        />
                        {validation.touched.peopleId &&
                        validation.errors.peopleId ? (
                          <div className="text-xs text-rose-400">
                            {validation.errors.peopleId}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2.5 w-full pt-3">
                    <div className="w-full flex gap-2.5">
                      <div className="flex flex-col gap-2.5 w-1/2 mb-20 relative">
                        <label htmlFor="isBillable">
                          Billing <span className="text-rose-400">*</span>
                        </label>
                        <DropdownFull
                          options={[
                            { name: 'Billable', id: 'true' },
                            { name: 'Non-Billable', id: 'false' },
                          ]}
                          name="name"
                          selected={validation.values.isBillable}
                          setSelected={(value) => {
                            validation.setFieldValue('isBillable', value);
                          }}
                        />
                        {validation?.values?.productServiceId &&
                          validation?.values?.isBillable === 'true' && (
                            <span className="text-sm text-slate-600 absolute top-[6.6rem]">
                              {getCurrencySymbolFromIso(
                                products.find(
                                  (product) =>
                                    product.id ===
                                    validation?.values?.productServiceId,
                                )?.currency,
                                currencies,
                              )}
                              {
                                products.find(
                                  (product) =>
                                    product.id ===
                                    validation?.values?.productServiceId,
                                )?.unitPrice
                              }{' '}
                              Per Hour
                            </span>
                          )}
                      </div>
                      <div className="w-1/2 flex gap-2.5">
                        <div className="flex flex-col gap-2.5 w-full">
                          <label>
                            Time & Date <span className="text-rose-400">*</span>
                          </label>
                          <div className="flex gap-4">
                            <div className="border border-[#D0D5DD] rounded-md flex flex-col overflow-hidden items-center justify-center gap-1 w-1/2 h-12 ">
                              <p className="text-center text-sm text-slate-500">
                                HOURS
                              </p>
                              <input
                                type="number"
                                className="h-5 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-[95%] form-input bg-none shadow-none font-medium border-none outline-none text-xl text-black placeholder:text-slate-600 p-0 text-center"
                                value={validation.values.hours}
                                onChange={(e) => {
                                  validation.setFieldValue(
                                    'hours',
                                    e.target.value,
                                  );
                                }}
                                placeholder="0"
                                name="hours"
                                id="hours"
                              />
                            </div>
                            <div className="border overflow-hidden border-[#D0D5DD] rounded-md flex flex-col items-center justify-center gap-1 w-1/2 h-12">
                              <p className="text-center text-sm text-slate-500">
                                MINUTES
                              </p>
                              <input
                                type="number"
                                className="h-5 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-[95%] form-input bg-none shadow-none font-medium border-none outline-none text-xl text-black placeholder:text-slate-600 p-0 text-center"
                                value={validation.values.minutes}
                                onChange={(e) => {
                                  validation.setFieldValue(
                                    'minutes',
                                    e.target.value,
                                  );
                                }}
                                placeholder="0"
                                name="minutes"
                                id="minutes"
                              />
                            </div>
                          </div>
                          <DatePickerDash
                            placeholder={'Select Date'}
                            value={
                              validation.values.date
                                ? validation.values.date
                                : null
                            }
                            options={options}
                            onChange={(selectedDates) => {
                              validation.setFieldValue(
                                'date',
                                selectedDates[0],
                              );
                            }}
                            name="date"
                            id="date"
                            error={
                              validation.touched.date && validation.errors.date
                                ? validation.errors.date
                                : ''
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onBlur={(e) => {
                              if (
                                !e.target.classList?.value?.includes('active')
                              ) {
                                validation.setFieldValue(
                                  'date',
                                  e.target.value,
                                );
                              }
                            }}
                          />
                          {(validation.touched.minutes ||
                            validation.touched.hours) &&
                          (validation.errors.minutes ||
                            validation.errors.hours) ? (
                            <div className="text-xs text-rose-400">
                              {validation.errors.minutes ||
                                validation.errors.hours}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-2.5 -mt-4">
                    <label htmlFor="notes">Notes</label>
                    <textarea
                      className="text-[14px] w-full border border-[#D0D5DD] rounded-[12px] p-2"
                      value={validation.values.notes}
                      onChange={validation.handleChange}
                      placeholder=""
                      name="notes"
                      id="notes"
                      onBlur={validation.handleBlur}
                    />
                  </div>
                </div>
                <div className=" bg-white flex py-[1rem] px-[30px] justify-end items-center gap-4 self-stretch w-full ">
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      type="button"
                      className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalOpen(false);
                      }}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-indigo-500 text-white font-normal text-base leading-6 shadow-sm
                      disabled:bg-indigo-400    disabled:cursor-not-allowed"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Loader width="w-4" height="w-4" color="#FFFFFF" />
                      ) : (
                        'Save'
                      )}
                    </button>
                  </div>
                </div>
              </>
            </Form>
          );
        }}
      </Formik>
    </ModalBasic>
  );
};

export default AddTimeModal;
